import { useMutation, useQueryClient } from 'react-query';
import { BASE_API_URL } from 'constants/env';
import { API_METHODS } from 'constants/api-methods';
import { apiHeaders } from 'utils/api-headers';
import { store } from 'index';
import toast from 'react-hot-toast';
import { userLinqProductsQueryKeys } from 'utils/query-keys'; 

interface Params { 
  productUrl: string;
  cardCode: string | undefined;
  user_uuid: string | undefined;
  }

const handleActivateProducts = async ({ productUrl, cardCode, user_uuid }: Params) => {
  
    if (!productUrl || !cardCode || !user_uuid) {
      throw new Error('Missing required fields: productUrl, cardCode, or user_uuid');
    }

    const parts = productUrl.split('/');
    const aliasCode = parts[parts.length - 1];

    const response = await fetch(`${BASE_API_URL}/admin/api/v2/users/${user_uuid}/user_linq_products`, {
      method: API_METHODS.POST,
      headers: apiHeaders(store.getState()),
      body: JSON.stringify({
        product_url: productUrl,
        alias_code: aliasCode,
        card_code: cardCode,
        nick_name: 'Link Products', 
        
      }),
    });

    if (!response.ok) {
      throw new Error(`Failed to activate product. Status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData;

  
};

// Mutation hook
export const useActivateProducts = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ productUrl, cardCode, user_uuid,}: Params) => {
      return handleActivateProducts({
        productUrl,
        cardCode,
        user_uuid,
      });
    },
    {
      onMutate: () => {
        toast('Product is being activated', { icon: '🕓' });
      },
      onSuccess: (data, { user_uuid }) => {
        toast.success('Product activated successfully!');

       
        if (user_uuid) {
          queryClient.invalidateQueries(userLinqProductsQueryKeys.index(user_uuid));
        }
      },
      onError: (error, { user_uuid }) => {
        console.error('Activation error:', error);
        toast.error(`Failed to activate product: {error.message}`);

        
        if (user_uuid) {
          queryClient.invalidateQueries(userLinqProductsQueryKeys.index(user_uuid));
        }
      },
    }
  );
};