import { WEB_APP_URL } from 'constants/env'
import useMutationDeleteCard from 'hooks/use-mutation-delete-card'
import useUpdateWalletPass from 'hooks/use-update-wallet-pass'
import { Card as LinqCard } from 'interfaces/card'
import { User } from 'interfaces/user'
import React, { useState } from 'react'
import { Button, Card, Dropdown, Modal, Form } from 'react-bootstrap'
import { QueryKey } from 'react-query'
import CopyToClipboardButton from './copy-to-clipboard-button'
import TransferCardToUserModal from './transfer-card-to-user-modal'
import { ActivateUserProductModal } from './activate-user-product-modal'


const UserCardCard = ({
  card,
  deleteCardForUserMutation,
  user,
  cardsForUserQueryKey,
}: {
  card: LinqCard
  deleteCardForUserMutation: ReturnType<typeof useMutationDeleteCard>
  user: User | null
  cardsForUserQueryKey: QueryKey
}) => {
  const [showTransferCardModal, setShowTransferCardModal] = useState(false)
  const { mutate: updateWalletPass } = useUpdateWalletPass(card?.id)
  const [showActivateUserProductModal, setShowActivateUserProductModal] = useState(false)
  const handleActivateUserProductClicked = () => {
    setShowActivateUserProductModal(true) 
  }
  // Delete Card
  const handleDeleteCardClicked = (card: LinqCard) => {
    if (
      window.confirm(
        'Are you sure you want to delete this page? This will delete the page and remove the page from all connections. This cannot be undone. Make sure that you have consent from the owner of this account to delete it.'
      ) === true
    ) {
      if (
        window.confirm(
          'Ok. If you are really really sure, please just confirm once more before we delete this page forever...'
        )
      ) {
        deleteCardForUserMutation.mutate({
          userUuid: user?.uuid,
          cardId: card.id,
          force: true,
        })
      }
    }
  }

  
     
  
 



  const handleTransferToOtherUserClick = () => {
    setShowTransferCardModal(true)
  }

  return (
    <>
      <Card bg="secondary" style={{ width: '190px', margin: '1%' }}>
        <Card.Img
          variant="top"
          style={{ height: '190px', objectFit: 'cover' }}
          src={card.photo_url || `/default_profile_photo.png`}
        />
        <Card.Body>
          <Card.Title>{card?.contact?.full_name}</Card.Title>
          {card?.contact?.title ? (
            <Card.Text>{card?.contact?.title}</Card.Text>
          ) : (
            <Card.Text className="text-muted">No title</Card.Text>
          )}
          {card?.contact?.company ? (
            <Card.Text>{card?.contact?.company}</Card.Text>
          ) : (
            <Card.Text className="text-muted">No company</Card.Text>
          )}

          <Card.Text className="mt-3">
            <a href={card.connecting_url} target="_blank" rel="noreferrer">
              {`${WEB_APP_URL?.replace('https://', '')}/${
                card.connecting_code
              }`}
            </a>
          </Card.Text>
          <Card.Text className="mt-3 text-muted">Page ID: {card.id}</Card.Text>
          <Dropdown>
            <Dropdown.Toggle variant="success">Page Actions</Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleTransferToOtherUserClick()}>
                Transfer To User
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={() => updateWalletPass()}>
                Update Wallet Pass
              </Dropdown.Item>
              
              <Dropdown.Divider />
              <Dropdown.Item onClick={() => handleActivateUserProductClicked()}>
                Activate Product
              </Dropdown.Item>
              
              <Dropdown.Divider />
              <Dropdown.Item
                style={{ color: 'red' }}
                onClick={() => handleDeleteCardClicked(card)}
              >
                Force Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Card.Body>
        <CopyToClipboardButton
          buttonVariant="light"
          copiedText="Copied"
          copyText="Copy Page URL"
          stringToCopy={card.connecting_url}
        />
      </Card>
      <TransferCardToUserModal
        show={showTransferCardModal}
        card={card}
        oldUser={user}
        handleClose={() => setShowTransferCardModal(false)}
        cardsForUserQueryKey={cardsForUserQueryKey}
      />
      <ActivateUserProductModal
        show={showActivateUserProductModal}
        user={user} 
        card={card}
        handleClose={() => {
          setShowActivateUserProductModal(false)
        }}
      />
    </>
  )
}

export default UserCardCard
