import { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useActivateProducts } from 'hooks/use-activate-product'; 
import { User } from 'interfaces/user';
import { Card } from 'interfaces/card';

interface ActivateProductModalProps {
  show: boolean;
  handleClose: () => void;
  user: User | null;
  card: Card | null; 
}

export const ActivateUserProductModal = ({ user, card, show, handleClose }: ActivateProductModalProps) => {
  const [productUrl, setProductUrl] = useState('') 
  const activateProductMutation = useActivateProducts(); 

  const handleActivatedProduct = () => {

      activateProductMutation.mutate({
        productUrl,
        cardCode: card?.connecting_code || '',
        user_uuid: user?.uuid || '',
      })
    handleClose(); 
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Activate Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formProductUrl">
            <Form.Label>Product URL</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter product URL"
              value={productUrl}
              onChange={(e) => setProductUrl(e.target.value)}
              style={{ backgroundColor: '#444', color: 'white' }}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Dismiss
        </Button>
        <Button variant="success" onClick={handleActivatedProduct}>
          Activate
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
