import { LineItemData } from 'interfaces/line-item'
import React, { useState } from 'react'
import { Form, Alert, Button } from 'react-bootstrap'
import { FuzzySearchModal } from './fuzzy-search-modal'

export const StripeSelect = ({
  setState,
  id,
  value,
  data = [],
  nullValue,
  label,
  labelDescription,
  showRefetchButton = false,
  refetch = () => {},
  isFetching,
}: {
  setState: React.Dispatch<React.SetStateAction<string | number | undefined>>
  id: string
  value?: string | number
  data?: LineItemData[]
  nullValue: string
  label: string
  labelDescription: string
  showRefetchButton?: boolean
  refetch?: () => void
  isFetching: boolean
}) => {
  const [showModal, setShowModal] = useState(false)

  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState(e.target.value === 'None' ? '' : e.target.value)
  }

  const itemName = (item?: LineItemData | null | undefined) => {
    if (!item) return ''
    if (id === 'coupons') {
      return `${item.name} - ${
        item.amount_off
          ? ` ${Number(item.amount_off / 100).toFixed(2)} `
          : ` ${item.percent_off}% `
      } ${
        item.duration === 'repeating'
          ? `for ${item.duration_in_months} month`
          : item.duration
      }`
    }
    if (id === 'productPrice') {
      return `${
        item?.metadata?.internal_description
          ? item?.metadata?.internal_description
          : `$ ${(Number(item?.unit_amount) / 100).toFixed(2)}`
      }`
    }
    return item?.name
  }

  const findItem = (id?: number | string) =>
    id ? data.find((item) => item.id === id) : null

  const validData =
    data
      ?.filter((item) => {
        if (id === 'coupons') {
          return item.valid
        }
        if (id === 'productPrice' || id === 'products') {
          return item.active
        }
        return true
      })
      .map((item) => ({ ...item, name: itemName(item) })) || []

  const onCloseModal = () => setShowModal(false)

  const handleSelectClick = (item: LineItemData) => {
    setState(item?.id || '')
    setShowModal(false)
  }

  const handleNewCouponClick = () => {
    window.open('https://dashboard.stripe.com/coupons', '_blank')
  }

  return (
    <>
      <div className="mt-4 mb-4">
        <div className="form-floating" style={{ display: 'flex' }}>
          <select
            onChange={handleOnChange}
            className="form-select"
            id={id}
            value={value}
            disabled={isFetching}
          >
            <option value="None">{nullValue}</option>
            {validData?.map((item) => (
              <option key={item.id} value={item.id}>
                {itemName(item)}
              </option>
            ))}
          </select>
          <label htmlFor={label}>{labelDescription}</label>
          <Button
            style={{ marginLeft: '8px' }}
            onClick={() => setShowModal(true)}
          >
            <ion-icon name="search" />
          </Button>
        </div>
        {showRefetchButton && (
          <Button
            className="mt-2"
            disabled={isFetching}
            onClick={() => refetch()}
          >
            {isFetching ? 'Loading...' : `Reload ${labelDescription}`}
          </Button>
        )}
        {id === 'coupons' && (
          <Button
            variant="outline-light"
            className="mt-2 ms-2"
            onClick={handleNewCouponClick}
          >
            Stripe Coupons <ion-icon name="open-outline" />
          </Button>
        )}
      </div>
      <FuzzySearchModal
        show={showModal}
        onClose={onCloseModal}
        initialQuery={itemName(findItem(value))}
        itemName={itemName}
        handleSelectClick={handleSelectClick}
        selectButtonText="Select"
        data={validData}
        modalTitle={labelDescription || label}
      />
    </>
  )
}

export const AdjustProductQuantity = ({
  productQuantity,
  setProductQuantity,
  setAllowQuantityAdjusting,
  allowQuantityAdjusting,
}: {
  productQuantity: number
  setProductQuantity: React.Dispatch<React.SetStateAction<number>>
  setAllowQuantityAdjusting: React.Dispatch<React.SetStateAction<boolean>>
  allowQuantityAdjusting: boolean
}) => {
  const handleChangeQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedQuantity = Number(e.target.value)
    if (updatedQuantity < 0) {
      window.alert('Quantity can not be less than 0')
      return
    }
    setProductQuantity(updatedQuantity)
    if (updatedQuantity >= 999) {
      setAllowQuantityAdjusting(false)
    } else if (updatedQuantity < 999 && allowQuantityAdjusting) {
      setAllowQuantityAdjusting(true)
    } else if (updatedQuantity < 999 && !allowQuantityAdjusting) {
      setAllowQuantityAdjusting(false)
    }
  }
  return (
    <div className="form-floating mt-4 mb-4">
      <Form.Group className="mb-3">
        <Form.Label
          style={{
            background: '#fff',
            width: '100%',
            marginBottom: '-0.5rem',
            padding: '0.5rem',
            color: 'gray',
            borderRadius: '0.25rem',
          }}
        >
          Quantity
        </Form.Label>
        <Form.Control
          style={{ borderColor: '#fff' }}
          type="number"
          value={productQuantity}
          onChange={handleChangeQuantity}
          placeholder="0"
        />
      </Form.Group>
    </div>
  )
}

export const AdjustSeatCountCheckBox = ({
  productQuantity,
  allowQuantityAdjusting,
  setAllowQuantityAdjusting,
}: {
  productQuantity: number
  allowQuantityAdjusting: boolean
  setAllowQuantityAdjusting: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  return (
    <div className="form-check mt-4">
      <input
        key={Math.random()}
        className="form-check-input"
        type="checkbox"
        disabled={productQuantity > 999 || productQuantity < 1}
        checked={allowQuantityAdjusting}
        onChange={(e) => setAllowQuantityAdjusting(e.target.checked)}
        id="adjustableSeatCountCheck"
      />
      <label className="form-check-label" htmlFor="adjustableSeatCountCheck">
        Let User Adjust Product Quantity?
      </label>
    </div>
  )
}

export const AdjustSeatCountWarning = ({
  productQuantity,
  allowQuantityAdjusting,
}: {
  productQuantity: number
  allowQuantityAdjusting: boolean
}) => {
  return (
    <>
      {productQuantity > 999 && !allowQuantityAdjusting ? (
        <Alert variant="secondary" className="mt-2">
          Can't have adjustable quantity enabled when the quantity is over 999.
        </Alert>
      ) : productQuantity < 5 && !allowQuantityAdjusting ? (
        <Alert variant="secondary" className="mt-2">
          Can't have adjustable quantity enabled when the quantity is less than
          1
        </Alert>
      ) : (
        <Alert variant="secondary" className="mt-2">
          Quantity can only be adjusted from 1 to 999 and at checkout by the
          user.
        </Alert>
      )}
    </>
  )
}

export const LinqOneDiscountInput = ({
  linqOneDiscount,
  setLinqOneDiscount,
}: {
  linqOneDiscount: string | number | undefined
  setLinqOneDiscount: (value: string | number | undefined) => void
}) => {
  const handleChangeQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const percent = Number(e.target.value)

    if (Number.isNaN(percent) || percent < 0 || percent > 100) {
      alert('Invalid discount! Please enter a value between 0 and 100.')
      return false
    }

    setLinqOneDiscount(percent)
  }
  return (
    <div className="form-floating mt-4 mb-4">
      <Form.Group className="mb-3">
        <Form.Label
          style={{
            background: '#fff',
            width: '100%',
            marginBottom: '-0.5rem',
            padding: '0.5rem',
            color: 'gray',
            borderRadius: '0.25rem',
          }}
        >
          Linq One Discount
        </Form.Label>
        <Form.Control
          style={{ borderColor: '#fff' }}
          type="number"
          value={linqOneDiscount}
          onChange={handleChangeQuantity}
          placeholder="0"
        />
      </Form.Group>
    </div>
  )
}

export const LinqCouponId = ({
  couponId,
  setCouponId,
}: {
  couponId: string | undefined
  setCouponId: (value: string | undefined) => void
}) => {
  const handleCouponChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const coupon = e.target.value.trim()
    setCouponId(coupon)
  }
  return (
    <div className="form-floating mt-4 mb-4">
      <Form.Group>
        <Form.Label
          style={{
            background: '#fff',
            width: '100%',
            marginBottom: '-0.5rem',
            padding: '0.5rem',
            color: 'gray',
            borderRadius: '0.25rem',
          }}
        >
          Stripe Coupon Id
        </Form.Label>
        <Form.Control
          style={{ borderColor: '#fff' }}
          type="text"
          value={couponId}
          onChange={handleCouponChange}
          placeholder="N/A"
        />
      </Form.Group>
    </div>
  )
}

export const TrialCheckBox = ({
  hasTrial,
  setHasTrial,
}: {
  hasTrial: boolean
  setHasTrial: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  return (
    <div className="form-check mt-4">
      <input
        className="form-check-input"
        type="checkbox"
        checked={hasTrial}
        onChange={(e) => setHasTrial(e.target.checked)}
        id="trialCheck"
      />
      <label className="form-check-label" htmlFor="trialCheck">
        Has Trial?
      </label>
    </div>
  )
}
